


































import { Vue, Component, Watch } from 'vue-property-decorator';
import { State as StateClass, Action } from 'vuex-class';
import { State } from '@/models/State';
import { bloqifyFirestore } from '@/boot/firebase';

@Component({
  components: {},
})
export default class Investors extends Vue {
  @StateClass boundUsers!: State['boundUsers'];
  @StateClass boundIdentificationRequests!: State['boundIdentificationRequests'];

  @Action bindFirestoreReferences!: Function;
  @Action unbindFirestoreReferences!: Function;

  loadingPromise: Promise<any> = Promise.resolve();

  mounted(): void {
    this.loadingPromise = this.bindFirestoreReferences([
      {
        name: 'boundUsers',
        ref: bloqifyFirestore.collection('investors').orderBy('createdDateTime', 'desc'),
      },
      {
        name: 'boundIdentificationRequests',
        ref: bloqifyFirestore.collection('identificationRequests').orderBy('createdDateTime', 'desc'),
      },
    ]);
  }

  beforeDestroy(): void {
    if (!this.$route.fullPath.includes('investors')) {
      this.unbindFirestoreReferences([
        { name: 'boundUsers' },
        { name: 'boundIdentificationRequests' },
      ]);
    }
  }
}
