
























  import { ValidationProvider } from 'vee-validate';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';

@Component({
  components: {
    ValidationProvider,
    FormInvalidMessage,
  },
})
  /** Class for the FormFileInput
   * @prop  rules Only supports required */
  export default class FormFileInput extends Vue {
  @Prop() name!: string;
  @Prop({ default: 'ext:jpg,jpeg,png,pdf' }) rules!: string;
  @Prop() label!: string;
  @Prop() accept!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: true }) bails!: boolean;

  currentValue: any = null;

  @Ref('validator') readonly validator!: InstanceType<typeof ValidationProvider>;

  async handleChange(event): Promise<void> {
    const { valid } = await this.validator.validate(event);

    if (valid) {
      this.$emit('change', event);
    }
  }
  }
