




















































































































































































































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { BusinessInvestor, Investor, isInvestor, KYCMethods, PrivateInvestor, User, UserStatus } from '@/models/users/User';
import UserDetailsSlice from '@/components/user/UserDetailsSlice.vue';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import VueLitebox from 'vue-litebox';
import { IdentificationRequestStatus, UserGender } from '@/models/identification-requests/IdentificationRequest';
import { Action, Getter, State as StateClass } from 'vuex-class';
import to from 'await-to-js';
import { bloqifyFirestore, bloqifyStorage } from '@/boot/firebase';
import { State } from '@/models/State';
import { GetUserIdentificationStatus } from '@/store/modules/user';
import { getName } from 'i18n-iso-countries';
import { Pescheck, PescheckStatus } from '@/models/identification-requests/pescheck';
import singleDocumentQuery from '@/mixins/singleDocumentQuery';
import ModifyStatusModal from '@/components/users/ModifyStatusModal.vue';
import { capitalize } from '@/filters/string';
import { PescheckResultStatusNameAndClass, pescheckResultStatusNameAndClass } from '@/components/users/PesCheckModal.vue';

@Component({
  components: {
    UserDetailsSlice,
    VueLitebox,
    ModifyStatusModal,
  },
  mixins: [
    singleDocumentQuery({
      ref: bloqifyFirestore.collection('investors'),
      stateSlice: 'boundUser',
      idName: 'userId',
    }),
  ],
})
export default class UserDetails extends Vue {
  @StateClass('boundUser') boundUser!: State['boundUser'];

  @Action handleIdentificationRequest!: Function;
  @Action deleteIdentificationRequest!: Function;
  @Getter getUserIdentificationStatus!: Function;
  @Action(addToastMessage) addToastMessage!: Function;
  @Action bindFirestoreReferences!: Function;
  @Getter getPendingChangeRequests!: State['dataChangeRequests'];
  @Action approveSensitiveDataChange!: Function;
  @Action rejectSensitiveDataChange!: Function;

  @Prop({ default: false, required: false }) approvalMode!: boolean;

  @Ref('statusToggle') readonly statusToggle!: HTMLInputElement;

  UserStatus = UserStatus;
  KYCMethods = KYCMethods;

  capitalize = capitalize;

  private _UserEnabled?: UserStatus;
  showModifyStatusModal: boolean = false;

  passports: { name: string, url: string }[] = [];
  liteboxImages: string[] = [];
  showLitebox = false;

  imgLoading = false;

  approvalProcessing = false;

  requestTypeNames: any = {
    bankAccount: 'Bank Account',
    name: 'Name',
  }

  mounted(): void {
    if (this.userId) {
      if (this.userIRstatus === 'initial' && this.$router.currentRoute.path !== `/user-details/${this.userId}/approval`) {
        this.$router.replace(`/user-details/${this.userId}/approval`);
      } else if (this.userIRstatus !== 'initial' && this.$router.currentRoute.path !== `/user-details/${this.userId}`) {
        this.$router.replace(`/user-details/${this.userId}`);
      }
    }
  }

  @Watch('userIRstatus')
  onNewIRstatus(newuserIRstatus: GetUserIdentificationStatus): void {
    if (this.userId) {
      if (newuserIRstatus === 'initial' && this.$router.currentRoute.path !== `/user-details/${this.userId}/approval`) {
        this.$router.replace(`/user-details/${this.userId}/approval`);
      } else if (newuserIRstatus !== 'initial' && this.$router.currentRoute.path !== `/user-details/${this.userId}`) {
        this.$router.replace(`/user-details/${this.userId}`);
      }
    }
  }

  get userId(): string {
    return this.$route.params.userId;
  }

  get user(): User | Investor | null {
    return this.boundUser ?? null;
  }

  get pendingNameChangeRequest(): any {
    return this.getPendingChangeRequests.find(
      ({ investor, type }: any) => investor.id === this.userId && type === 'name',
    );
  }

  get pendingBankAccountChangeRequest(): any {
    return this.getPendingChangeRequests.find(
      ({ investor, type }: any) => investor.id === this.userId && type === 'bankAccount',
    );
  }

  get stateOfLoad(): 'loaded' | 'loading' {
    if (this.boundUser) {
      return 'loaded';
    }
    return 'loading';
  }

  get userGender(): string | null {
    return (this.user && 'gender' in this.user) ? (this.user.gender === UserGender.Male ? 'Male' : 'Female') : null;
  }

  get userName(): string | null {
    return (this.user && 'name' in this.user) ? `${this.user.name} ${this.user.surname || ''}` : null;
  }

  get userType(): 'IDIN' | 'Business' | 'Private' | undefined {
    if (this.user && isInvestor(this.user)) {
      if (this.user.kycMethod === KYCMethods.Idin) {
        return 'IDIN';
      }
      if (this.user.kycMethod === KYCMethods.Business) {
        return 'Business';
      }
      return 'Private';
    }
    return undefined; // this will also be the case when the approval was done via an upgrade
  }

  // gives the identification request status maybe this should be a getter in vuex
  get userIRstatus(): GetUserIdentificationStatus {
    return this.getUserIdentificationStatus(this.user);
  }

  get userIRstatusTitle(): string {
    switch (this.userIRstatus) {
      case IdentificationRequestStatus.Initial:
        return 'Pending';
      case IdentificationRequestStatus.Approved:
        return 'Approved';
      case IdentificationRequestStatus.Rejected:
        return 'Rejected';
      case 'none':
        return 'None';
      case 'error':
      default:
        return 'Error';
    }
  }

  get userEnabled(): boolean {
    if (!this.user) {
      return false;
    }
    if (!this._UserEnabled) {
      return this.user.status === UserStatus.Enabled;
    }
    return this._UserEnabled === UserStatus.Enabled;
  }

  set userEnabled(newValue: boolean) {
    this._UserEnabled = newValue ? UserStatus.Enabled : UserStatus.Disabled;
    this.showModifyStatusModal = true;
  }

  getCountryName(iso: string): string {
    return getName(iso, 'en');
  }

  get bkrSan(): PescheckResultStatusNameAndClass {
    return pescheckResultStatusNameAndClass((this.user?.pescheck as Pescheck).result.bkrSan);
  }

  get bkrPep(): PescheckResultStatusNameAndClass {
    return pescheckResultStatusNameAndClass((this.user?.pescheck as Pescheck).result.bkrPep);
  }

  get statusClass(): string {
    if (!((this.user?.pescheck as Pescheck).status)) {
      return 'light';
    }
    switch ((this.user?.pescheck as Pescheck).status as PescheckStatus) {
      case PescheckStatus.Verified:
        return 'success';
      case PescheckStatus.In_progress:
        return 'warning';
      case PescheckStatus.Draft:
        return 'info';
      case PescheckStatus.Rejected:
        return 'danger';
      case PescheckStatus.Awaiting_verdict:
        return 'warning';
      default:
        return 'light';
    }
  }

  closeModal(para: 'success' | undefined): void {
    this.showModifyStatusModal = false;
    // Set the toggle back if the status change was cancelled
    if (para !== 'success') {
      const toInvertStatus = this._UserEnabled === UserStatus.Enabled;
      this._UserEnabled = !toInvertStatus ? UserStatus.Enabled : UserStatus.Disabled;
      this.statusToggle.checked = this.userEnabled;
    }
  }

  clickIR() {
    // todo we might want to display more information allow them to approve/reject the IR request in the future
  }

  /**
   * Retrieve image and show it with litebox
   */
  async openImage(which: 'passport' | 'kvk'): Promise<void> {
    this.imgLoading = true;
    const images: string = which === 'passport' ? (this.user as PrivateInvestor).passport : (this.user as BusinessInvestor).kvkImage;
    const found = this.passports.find((passport): boolean => passport.name === images);
    let url = '';
    if (!found) {
      const storageRef = bloqifyStorage.ref().child(images);
      const [getError, file] = await to(storageRef.getDownloadURL());
      if (getError) {
        this.imgLoading = false;

        this.addToastMessage({
          text: getError.message || 'There was an error retrieving the passport.',
          type: 'danger',
        });

        return;
      }

      this.passports.push({ name: images, url: file });
      url = file;
    } else {
      url = found.url;
    }
    this.imgLoading = false;

    // Opening litebox with the proper image
    this.liteboxImages = [url];
    this.handleLitebox(true);
  }

  handleLitebox(status: boolean) {
    this.showLitebox = status;
  }

  async handleRequest(action: 'approved' | 'rejected'): Promise<void> {
    this.approvalProcessing = true;
    await this.handleIdentificationRequest({ userId: this.userId, action });
    // after updating we need to get the new data
    this.bindFirestoreReferences([
      {
        name: 'boundUser',
        ref: bloqifyFirestore.collection('investors').doc(this.userId),
      },
    ]);
    this.approvalProcessing = false;
  }

  formatObjectValue(value: any): string {
    return `${value.name} ${value.surname}`;
  }
}
