





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { PescheckResultStatus, PescheckStatus } from '@/models/identification-requests/pescheck';
import Modal from '@/components/common/Modal.vue';

export type PescheckResultStatusNameAndClass = { name: string, class: string, };
export const pescheckResultStatusNameAndClass = (status: PescheckResultStatus): PescheckResultStatusNameAndClass => {
  switch (status) {
  case PescheckResultStatus.Na:
    return { name: 'Na', class: 'info' };
  case PescheckResultStatus.Green:
    return { name: 'No', class: 'success' };
  case PescheckResultStatus.Red:
    return { name: 'Yes', class: 'danger' };
  case PescheckResultStatus.Orange:
  default:
    return { name: 'Ambiguous', class: 'warning' };
  }
};

export type PescheckDisplay = {
  status: PescheckStatus,
  bkrPep: PescheckResultStatus,
  bkrSan: PescheckResultStatus,
} | null;
@Component({
  components: {
    Modal,
  },
})
export default class PesCheckModal extends Vue {
  @Prop() pescheck!: Exclude<PescheckDisplay, null>;
  pescheckResultStatusNameAndClass = pescheckResultStatusNameAndClass;

  get bkrSan(): PescheckResultStatusNameAndClass {
    return pescheckResultStatusNameAndClass(this.pescheck.bkrSan);
  }

  get bkrPep(): PescheckResultStatusNameAndClass {
    return pescheckResultStatusNameAndClass(this.pescheck.bkrPep);
  }

  get statusClass(): string {
    switch (this.pescheck.status as PescheckStatus) {
    case PescheckStatus.Verified:
      return 'success';
    case PescheckStatus.In_progress:
      return 'warning';
    case PescheckStatus.Draft:
      return 'info';
    case PescheckStatus.Rejected:
      return 'danger';
    case PescheckStatus.Awaiting_verdict:
    default:
      return 'warning';
    }
  }
}
