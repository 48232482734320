














































































































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Getter, State as StateClass } from 'vuex-class';
import FileSaver from 'file-saver';
import pick from 'lodash.pick';
// @ts-ignore
import json2csv from 'json2csv/dist/json2csv.umd';
import { ManagerRole } from '@/models/manager/Manager';
import { DataChangeRequest, Investor, User, UserStatus, UserTier } from '@/models/users/User';
import { IdentificationRequest, IdentificationRequestStatus } from '@/models/identification-requests/IdentificationRequest';
import DownloadPDFModal from '@/components/users/DownloadPDFModal.vue';
import { Pescheck, PescheckStatus } from '@/models/identification-requests/pescheck';
import PesCheckModal, { PescheckDisplay } from '@/components/users/PesCheckModal.vue';
import { Event } from 'vue-tables-2';
import ModifyStatusModal from '@/components/users/ModifyStatusModal.vue';
import ChangesRequestedModal from '@/components/users/ChangesRequestedModal.vue';
import Investors from '@/views/Users.vue';
import { GetUserIdentificationStatus } from '@/store/modules/user';
import { State } from '@/models/State';

type TierFilter = UserTier | 'all';

interface UserDisplayTable extends Omit<| Investors, 'pescheck'> {
  id: any;
  pescheck: PescheckDisplay | undefined;
  userIRstatus: GetUserIdentificationStatus;
}

function deepValues(object: object, fields?: string[], deep: boolean = false): string | string[] {
  const array: string[] = Object.values(fields?.length ? pick(object, fields) : object).map((value: any) => {
    if (Array.isArray(value)) {
      return value;
    }

    if (typeof value === 'object' && value !== null && value !== undefined) {
      return deepValues(value, [], true);
    }

    return value;
  });

  if (deep) {
    return array;
  }

  return array.flat().join(' ').toLowerCase();
}

@Component({
  components: {
    PesCheckModal,
    DownloadPDFModal,
    ModifyStatusModal,
    ChangesRequestedModal,
  },
})
export default class TabAllInvestors extends Vue {
  columns = [
    'customId',
    'name',
    'surname',
    'email',
    'tier',
    'userIRstatus',
    'pescheck',
    'createdDateTime',
    'changeRequests',
    'dropdown',
  ];
  combinedQueryFields = ['name', 'surname', 'email', 'tier', 'userIRstatus']
  options = {
    headings: {
      customId: 'id',
      name: 'Name',
      surname: 'surname',
      email: 'Email',
      tier: 'Tier',
      userIRstatus: 'Status',
      pescheck: 'PES',
      createdDateTime: 'Registered At',
      changeRequests: 'Request',
      dropdown: '',
    },
    descOrderColumns: ['changeRequests'],
    filterable: [
      'customId',
      'name',
      'surname',
      'email',
      'tier',
      'userIRstatus',
      'pescheck',
      'createdDateTimeString',
      'changeRequests',
      'combinedValues',
    ],
    // columnsClasses strings need to have a space at the end
    // because vue-tables-2 adds classes runtime without a space before
    columnsClasses: {
      customId: 'table__col--customId table__col--xs ',
      name: 'table__col--name table__col--s ',
      surname: 'table__col--surname table__col--s ',
      email: 'table__col--email table__col--l ',
      userIRstatus: 'table__col--userIRstatus table__col--s ',
      pescheck: 'table__col--pescheck table__col--xs ',
      tier: 'table__col--tier table__col--xs ',
      createdDateTime: 'table__col--createdDateTime table__col--m ',
      changeRequests: 'table__col--changeRequests table__col--s ',
      dropdown: 'table__col--dropdown align-middle table__col--xs ',
    },
    skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table,
    customFilters: [
      {
        name: 'tier',
        callback(row, query) {
          return row.tier === query;
        },
      },
    ],
    filterAlgorithm: {
      combinedValues(row: any, queryString: string) {
        const queryArray = queryString.toLowerCase().split(' ');
        return queryArray.every((queryWord) => row.combinedValues.includes(queryWord));
      },
    },
    customSorting: {
      userIRstatus(ascending) {
        return (a, b) => {
          if (a === b) {
            return 0;
          }
          const sortingOrder: Array<GetUserIdentificationStatus | UserStatus.Disabled> = [
            UserStatus.Disabled,
            IdentificationRequestStatus.Rejected,
            GetUserIdentificationStatus.Error,
            GetUserIdentificationStatus.None,
            IdentificationRequestStatus.Initial,
            IdentificationRequestStatus.Approved,
          ];
          const aToPass = a.status === UserStatus.Disabled ? UserStatus.Disabled : a.userIRstatus;
          const bToPass = b.status === UserStatus.Disabled ? UserStatus.Disabled : b.userIRstatus;
          if (ascending) {
            return sortingOrder.indexOf(aToPass) > sortingOrder.indexOf(bToPass) ? 1 : -1;
          }

          return sortingOrder.indexOf(aToPass) <= sortingOrder.indexOf(bToPass) ? 1 : -1;
        };
      },
      pescheck(ascending) {
        return (a, b) => {
          const aPes = a.pescheck?.status || 'none';
          const bPes = b.pescheck?.status || 'none';
          if (aPes === bPes) {
            return 0;
          }
          const sortingOrder = [
            PescheckStatus.Verified,
            'none',
            PescheckStatus.In_progress,
            PescheckStatus.Draft,
            PescheckStatus.Awaiting_verdict,
            PescheckStatus.Rejected,
          ];
          if (ascending) {
            return sortingOrder.indexOf(aPes) > sortingOrder.indexOf(bPes) ? 1 : -1;
          }

          return sortingOrder.indexOf(aPes) <= sortingOrder.indexOf(bPes) ? 1 : -1;
        };
      },
    },
  };
  showDownloadPDFModal: boolean = false;
  showModifyStatusModal: boolean = false;
  showPesCheckModal: boolean = false;
  showChangesRequestedModal: boolean = false;
  selectedUser: any = null;
  UserTier = UserTier;
  currentTierFilter: TierFilter = 'all';

  @Getter getUserIdentificationStatus!: Function;
  @Ref('investorTable') investorTable!: any;

  @Prop({ default: false }) loadingBindings!: boolean;

  @Getter getCurrentManagerRole!: ManagerRole;
  @Getter getIdentificationRequestFromInvestorsListByInvestorId!: (id: string) => IdentificationRequest | undefined;
  @Getter getPendingChangeRequests!: State['dataChangeRequests'];

  @StateClass boundUsers!: Investor[];

  /**
   * Execute investor download.
   */
  downloadInvestorsCsv(): void {
    const BOM = '\uFEFF';
    const csv = BOM + json2csv.parse(this.boundUsers);

    // Present file download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'users.csv');
  }

  handleModal(modal: 'PesCheckModal' | 'modify' | 'download' | 'changeRequest', status: boolean, investor: any): void {
    this.selectedUser = investor;
    switch (modal) {
    case 'modify':
      this.showModifyStatusModal = status;
      break;
    case 'download':
      this.showDownloadPDFModal = status;
      break;
    case 'PesCheckModal':
      this.showPesCheckModal = status;
      break;
    case 'changeRequest':
      this.showChangesRequestedModal = status;
      break;
    default:
      break;
    }
  }

  get listUsers(): (UserDisplayTable | null)[] {
    return this.boundUsers.map((investor: User | Investors): any | null => {
      const userIRstatus: GetUserIdentificationStatus = this.getUserIdentificationStatus(investor);

      let pescheck: PescheckDisplay = null;
      const isPescheckObject = (pescheck): pescheck is Pescheck => !!pescheck.status;
      const pescheckOfUser = (investor as User).pescheck;
      if (pescheckOfUser && isPescheckObject(pescheckOfUser)) {
        pescheck = {
          status: pescheckOfUser.status,
          bkrPep: pescheckOfUser.result.bkrPep,
          bkrSan: pescheckOfUser.result.bkrSan,
        };
      }

      // Filter data change requests for this user
      const changeRequests = this.getPendingChangeRequests.filter(
        (dataChangeRequest: DataChangeRequest) => (dataChangeRequest.investor as User).id === (investor as User).id,
      ).length;

      // Create string for createdDateTime to be able to filter on it
      const createdDateTimeString: string = this.$options.filters?.transformDate(
        this.$options.filters?.convertUTCToLocalDate((investor as User).createdDateTime),
        'DD/MM/YYYY',
      );

      const user = {
        ...investor,
        pescheck,
        userIRstatus,
        id: (investor as UserDisplayTable).id,
        changeRequests: changeRequests || false,
        createdDateTimeString,
      };

      // Combines values from predefined fields into string for filtering
      const combinedValues = deepValues(user, this.combinedQueryFields) as string;

      return {
        ...user,
        combinedValues,
      };
    });
  }

  /**
   * Returns whether current browser allows file downloads.
   */
  get fileDownloadIsSupported(): boolean {
    try {
      return !!new Blob();
    } catch (e) {
      return false;
    }
  }

  /**
   * Allow changing investor status (enabled/disabled).
   */
  get allowChangingInvestorStatus(): boolean {
    return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
  }

  filterTableByTier(tier: TierFilter) {
    this.currentTierFilter = tier;
    if (tier === 'all') {
      Event.$emit('vue-tables.filter::tier', null);
      return;
    }
    Event.$emit('vue-tables.filter::tier', tier);
  }
}
