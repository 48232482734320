
























import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/common/Modal.vue';
import { Action } from 'vuex-class';

@Component({
  components: {
    Modal,
  },
})
export default class ChangesRequestedModal extends Vue {
  @Prop() userId!: string;
  @Prop() amountChangeRequests!: number;
}
