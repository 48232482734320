/**
 * Different Pescheck request general statuses.
 */
export enum PescheckStatus {
  /**
   * Request is created and information/files can be added.
   */
  Draft = 'draft',
  /**
   * This is the state after calling /api/process. the request is send to bkr/mitek.
   */
  In_progress = 'in_progress',
  /**
   * Check have been done and the person is OK/green.
   */
  Verified = 'verified',
  /**
   * Something went wrong with the checks.
   */
  Rejected = 'rejected',
  /**
   * There is a result but manual interaction is required.
   */
  Awaiting_verdict = 'awaiting_verdict',
}

/**
 *Different statuses that Pescheck has over it's different KYC checks.
 * 'na' means Pescheck is still running that check.
 */
export enum PescheckResultStatus {
  Na = 'na',
  Green = 'green',
  Orange = 'orange',
  Red = 'red',
}

/**
 * Optional KYC method Pescheck.
 * Pescheck request is executed after any other KYC method is launched/created by the user (iDIN, iR)
 */
export interface Pescheck {
  id: string;
  name: string | null;
  email: string | null;
  pack: string;
  secret: string;
  origin_country: string | null;
  birth_country: string | null;
  last_name: string | null;
  date_of_birth: string | null;
  mitek_id: string | null;
  status: PescheckStatus;
  result: {
    bkrPep: PescheckResultStatus;
    bkrSan: PescheckResultStatus;
    mitID: 'na' | 'Completed' | 'Rejected';
    mitOFAC: PescheckResultStatus;
    mitAutomaticCheck: PescheckResultStatus;
    mitTypeDocument: 'na' | string;
    mitStolen: PescheckResultStatus;
    mitExtract: PescheckResultStatus;
    mitMRZ: PescheckResultStatus;
    mitMRZCompare: PescheckResultStatus;
    mitManual: PescheckResultStatus;
    mitMRZFront: 'na' | string | null;
    mitPhotoIntegration: 'na' | string | null;
  }
}
